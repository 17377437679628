// -----------------------------------//
// HP SLIDER
// -----------------------------------//

.body_3919 #slider {
  position: relative;
  height: 100vh;
  max-height: 1080px;
  min-height: 550px;
}

// -----------------------------------//
// IP SLIDER
// -----------------------------------//

#slider {
  width: 100%;
  position: relative;
  min-height: 350px;
  z-index: 1;

  @media only screen and (max-width: 990px) {
    top: -20px;
  }
}

.backstretch {
  position: relative;
  // border-radius: 0% 0% 50% 50% / 0% 0% 5% 5% ;
  
  // for top of page gradient
  &:before {
    @include before(100%, 662px);
    background: url(/i/design/gradient.png) repeat-x top left;
    top: 0;
    left: 0;
  }

  &:after {
    @include before(100%, 662px);
    background: url(/i/design/gradient-btm.png) repeat-x bottom left;
    bottom: 0;
    left: 0;
  }

  img {
    top: 0px !important;
  }
}

// -----------------------------------//
// STRAPLINE
// -----------------------------------//

.strapline {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 170px;
  margin: 0 auto;
  text-align: center;
  color: #fff;
  font-size: 72px;
  font-style: italic;
  font-weight: 300;

  @media only screen and (max-width: 990px) {
    font-size: 50px;
  }

  @media only screen and (max-width: 610px) {
    font-size: 38px;
    bottom: 80px;
  }
}

// -----------------------------------//
// SCHOOL VIDEO
// -----------------------------------//

.school-video {
  position: absolute;
  right: 45px;
  top: 45px;
  width: 300px;
  height: 180px;
  background: url(/i/design/school-video-thumbnail.png);
  background-size: cover;
  // border: 5px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0px 0px 0px 5px rgba(255, 255, 255, 0.2);
  margin: 5px;
  border-radius: 32px;
  z-index: 999;

  @media only screen and (max-width: 1760px) {
    width: 250px;
    height: 130px;
    right: 20px;
    top: 20px;
  }

  @media only screen and (max-width: 1600px) {
    height: 100px;
  }

  @media only screen and (max-width: 1160px) {
    background: none;
    box-shadow: none;
    top: 0;
    height: 40px;
    z-index: 999;
  }

  @media only screen and (max-width: 990px) {
		display: none;
	}

  &:hover {
    filter: brightness(110%);
    transition: 0.5s;
    transform: scale(102%);
  }

  .school-video-text {
    color: #fff;
    font-style: italic;
    font-size: 20px;
    background: #2b418b;
    white-space: nowrap;
    padding: 5px 0;
    width: 100%;
    position: absolute;
    bottom: 0;
    border-bottom-left-radius: 32px;
    border-bottom-right-radius: 32px;

    @media only screen and (max-width: 1160px) {
      bottom: 5px;
    }

    img {
      display: inline;
      position: relative;
      top: -2px;

      &:hover {
        transition: 0.5s;
        transform: translateX(5px);
      }
    }

  }
}