$nav-link-bg-hov: rgba(43, 65, 139, 0.8);
$nav-dropdown-hov: rgba(43, 65, 139, 0.8);

// -----------------------------------//
// NAVIGATION WRAPPERS
// -----------------------------------//

nav#navholder {
	z-index: 400;
	@extend .clearfix;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: 180px;
	border-top: 1px solid rgba(255, 255, 255, 0.4);
	border-bottom: 1px solid rgba(255, 255, 255, 0.4);
	width: fit-content;

	@media only screen and (max-width: 1160px) {
		border-top: none;
		border-bottom: none;
	}

	>.wrapper {
		width: 990px;
		margin: 0 auto;
	}
}

// -----------------------------------//
// MAIN NAVIGATION
// -----------------------------------//

ul.nav {
	display: table;
	width: 990px;
	margin: 0;
	list-style: none;
	font-family: 'Open Sans', sans-serif;
	font-weight: 300;

	// Top Level List
	li {
		margin: 0;
		background: none;
		position: relative;
		display: inline-block;
		text-align: center;
		white-space: nowrap;
		padding: 0;

		a {
			height: 45px;
			line-height: 45px;
			display: block;
			text-align: center;
			font-size: 18px;
			color: #fff;
			padding: 0 30px;

			@media only screen and (max-width: 10500px) {
				padding: 0 15px;
			}
		}
	}

	>li:hover>a,
	>li>a:hover {
		filter: drop-shadow(0px 0px 2px #fff);
		color: #fff;
		text-decoration: none;
	}

	// 2nd Level List
	li {
		ul {
			position: absolute;
			left: -999em;
			margin: 0;
			padding: 5px 20px 20px 15px;
			background: none;
			opacity: 0;
			visibility: hidden;
			transition: (all 0.3s ease-in-out);
		}

		&:hover ul {
			left: auto;
			margin-left: -20px;
			opacity: 1;
			visibility: visible;
			transition: (all 0.3s ease-in-out);
		}

		&:active ul {
			transition: (opacity 0.1s ease-in-out);
		}
	}

	// 2nd Level List Items
	li li {
		float: none;
		display: block;
		padding: 0;
		height: auto;
		line-height: 35px;

		a {
			background: $nav-link-bg-hov;
			height: 39px !important;
			line-height: 28px !important;
			padding: 6px 12px;
			min-width: 170px;
			float: none;
			top: auto;
			display: block;
			position: static;
			text-align: left;
			text-decoration: none;
			font-size: 15px;
			text-transform: none;
		}

		&:hover,
		&:hover>a,
		a:hover,
		a:focus {
			background: $nav-dropdown-hov;
		}
	}

	// Prepend 'more' arrow to parent list items
	li li a.parent:after {
		margin: -1px 0 0 10px;
		content: url('/i/design/lite/nav-right-arrow-white.png');
		opacity: 1;
		line-height: 1;
	}

	// 3rd Level List
	ul ul {
		z-index: 505;
		padding-top: 0;
	}

	li:hover ul ul {
		left: 100%;
		top: 0;
		opacity: 0;
		visibility: hidden;
		transition: (all 0.3s ease-in-out);
	}

	ul li:hover ul {
		left: 100%;
		top: 0;
		opacity: 1;
		visibility: visible;
		transition: (all 0.3s ease-in-out);
	}

}

// -----------------------------------//
// NAVIGATION DOCKED ON SCROLL
// -----------------------------------//

#navholder.sticky.fixed {
	position: fixed;
	animation: move-down 1s ease forwards;
	background: rgba(#000, .8);
	left: 0;
	right: 0;
	margin: 0 auto;
	width: 100%;

	ul.nav {
		text-align: center;
	}

	ul.nav li a {
		height: 40px;
		line-height: 40px;
	}

	@keyframes move-down {
		from {
			top: -50px;
			opacity: 0;
		}

		to {
			top: 0;
			opacity: 1;
		}
	}
}